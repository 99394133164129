import { alpha, darken, lighten, Palette, PaletteColor, Theme } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import React from 'react';

import { FlashlyDarkTheme } from './schemes/FlashlyDarkTheme';
import { PureLightTheme } from './schemes/PureLightTheme';

type CustomPaletteColor = Omit<PaletteColor, 'contrastText'> & { lighter: string };

export interface ThemeColors {
  scheme: {
    primary: string;
    secondary: string;
    success: string;
    pending: string;
    warning: string;
    error: string;
    info: string;
    black: string;
    white: string;
  };
  functional: {
    label: string;
    appBackground: string;
    iconDefault: string;
    headerBackground: string;
    sidebarBackground: string;
    sidebarHeading: string;
    defaultText: string;
    containerBackground: string;
    cardBackground: string;
    textButtonBackground: string;
    divider: string;
    disabledBackground: string;
    disabledText: string;
  };
}

declare module '@mui/material/styles' {
  interface Theme {
    dimensions: {
      borderRadius: string;
      borderRadiusLg: string;
    };
    functionalColors: ThemeColors['functional'];
    sidebar: {
      background: React.CSSProperties['color'];
      heading: React.CSSProperties['color'];
      textColor: React.CSSProperties['color'];
      boxShadow: React.CSSProperties['color'];
      width: string;
      collapsedWidth: string;
    };
    header: {
      height: string;
      background: React.CSSProperties['color'];
    };
    palette: Partial<Palette> & {
      primary: CustomPaletteColor;
      secondary: CustomPaletteColor;
      error: CustomPaletteColor;
      success: CustomPaletteColor;
      pending: CustomPaletteColor;
      info: CustomPaletteColor;
      warning: CustomPaletteColor;
      shadows: { [key: string]: string };
    };
  }

  type ThemeOptions = Partial<Theme>;
}

declare module '@mui/material/Card' {
  interface CardPropsColorOverrides {
    highContrast: true;
  }
}

export function getBaseTheme(themeColors: ThemeColors) {
  const breakpoints = createBreakpoints({});
  const { functional: functionalColors, scheme: colorScheme } = themeColors;

  const _colors = {
    shadows: {
      success: '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
      error: '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
      info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
      primary: '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
      warning: '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
      elevated: '0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
      mildElevation: '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
      greaterElevation: '0px 9px 20px rgb(159 162 191 / 18%), 0px 1px 5px rgb(159 162 191 / 0.32)',
    },
    alpha: {
      white: {
        5: alpha(colorScheme.white, 0.02),
        10: alpha(colorScheme.white, 0.1),
        30: alpha(colorScheme.white, 0.3),
        50: alpha(colorScheme.white, 0.5),
        70: alpha(colorScheme.white, 0.7),
        100: colorScheme.white,
      },
      black: {
        5: alpha(colorScheme.black, 0.02),
        10: alpha(colorScheme.black, 0.1),
        30: alpha(colorScheme.black, 0.3),
        50: alpha(colorScheme.black, 0.5),
        70: alpha(colorScheme.black, 0.7),
        100: colorScheme.black,
      },
    },
    secondary: {
      lighter: alpha(colorScheme.secondary, 0.1),
      light: lighten(colorScheme.secondary, 0.3),
      main: colorScheme.secondary,
      dark: darken(colorScheme.secondary, 0.2),
    },
    primary: {
      lighter: alpha(colorScheme.primary, 0.1),
      light: lighten(colorScheme.primary, 0.3),
      main: colorScheme.primary,
      dark: darken(colorScheme.primary, 0.35),
    },
    success: {
      lighter: alpha(colorScheme.success, 0.1),
      light: lighten(colorScheme.success, 0.3),
      main: colorScheme.success,
      dark: darken(colorScheme.success, 0.2),
    },
    pending: {
      lighter: alpha(colorScheme.pending, 0.1),
      light: lighten(colorScheme.pending, 0.3),
      main: colorScheme.pending,
      dark: darken(colorScheme.pending, 0.2),
    },
    warning: {
      lighter: alpha(colorScheme.warning, 0.1),
      light: lighten(colorScheme.warning, 0.3),
      main: colorScheme.warning,
      dark: darken(colorScheme.warning, 0.2),
    },
    error: {
      lighter: alpha(colorScheme.error, 0.1),
      light: lighten(colorScheme.error, 0.3),
      main: colorScheme.error,
      dark: darken(colorScheme.error, 0.2),
    },
    info: {
      lighter: alpha(colorScheme.info, 0.1),
      light: lighten(colorScheme.info, 0.3),
      main: colorScheme.info,
      dark: darken(colorScheme.info, 0.2),
    },
  };

  return {
    header: {
      height: '64px',
      background: functionalColors.headerBackground,
    },
    sidebar: {
      background: functionalColors.sidebarBackground,
      heading: functionalColors.sidebarHeading,
      textColor: colorScheme.white,
      boxShadow: '2px 0 3px rgba(159, 162, 191, .18), 1px 0 1px rgba(159, 162, 191, 0.32)',
      width: '240px',
      collapsedWidth: '64px',
    },
    dimensions: {
      borderRadius: '4px',
      borderRadiusLg: '6px',
    },
    functionalColors,
    spacing: 9,
    palette: {
      mode: '',
      primary: _colors.primary,
      secondary: _colors.secondary,
      error: _colors.error,
      success: _colors.success,
      pending: _colors.pending,
      info: _colors.info,
      warning: _colors.warning,
      shadows: _colors.shadows,
      text: {
        primary: functionalColors.defaultText,
        secondary: alpha(functionalColors.defaultText, 0.7),
        disabled: functionalColors.disabledText,
      },
      background: {
        paper: functionalColors.containerBackground,
        default: functionalColors.appBackground,
      },
      action: {
        active: _colors.alpha.black[100],
        hover: _colors.primary.lighter,
        hoverOpacity: 0.1,
        selected: _colors.alpha.black[10],
        selectedOpacity: 0.1,
        disabled: _colors.alpha.black[50],
        disabledBackground: functionalColors.disabledBackground,
        disabledOpacity: 0.38,
        focus: _colors.alpha.black[10],
        focusOpacity: 0.05,
        activatedOpacity: 0.12,
      },
      tonalOffset: 0.5,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1840,
      },
    },
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(2px)',

            '&.MuiBackdrop-invisible': {
              backgroundColor: 'transparent',
              backdropFilter: 'blur(2px)',
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            [breakpoints.down('sm')]: {
              paddingLeft: '18px',
              paddingRight: '18px',
            },
            [breakpoints.between('sm', 'lg')]: {
              paddingLeft: '24px',
              paddingRight: '24px',
            },
            [breakpoints.up('lg')]: {
              paddingLeft: '40px',
              paddingRight: '40px',
            },
            '& .MuiTypography-subtitle2': {
              color: functionalColors.defaultText,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            marginLeft: 8,
            marginRight: 8,
            fontWeight: 'bold',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          'html, body': {
            width: '100%',
            height: '100%',
          },
          body: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
            flex: 1,
          },
          '#root': {
            width: '100%',
            height: '100%',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          },
          html: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
            MozOsxFontSmoothing: 'grayscale',
            WebkitFontSmoothing: 'antialiased',
          },
          '.child-popover .MuiPaper-root .MuiList-root': {
            flexDirection: 'column',
          },
          '#nprogress': {
            pointerEvents: 'none',
          },
          '#nprogress .bar': {
            background: _colors.primary.lighter,
          },
          '#nprogress .spinner-icon': {
            borderTopColor: _colors.primary.lighter,
            borderLeftColor: _colors.primary.lighter,
          },
          '#nprogress .peg': {
            boxShadow: '0 0 15px ' + _colors.primary.lighter + ', 0 0 8px' + _colors.primary.light,
          },
          ':root': {
            '--swiper-theme-color': _colors.primary.main,
          },
          code: {
            background: _colors.info.lighter,
            color: _colors.info.dark,
            borderRadius: 4,
            padding: 4,
          },
          '@keyframes ripple': {
            '0%': {
              transform: 'scale(.8)',
              opacity: 1,
            },
            '100%': {
              transform: 'scale(2.8)',
              opacity: 0,
            },
          },
          '@keyframes float': {
            '0%': {
              transform: 'translate(0%, 0%)',
            },
            '100%': {
              transform: 'translate(3%, 3%)',
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: '4px',
            '&&': {
              boxShadow: _colors.shadows.mildElevation,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: functionalColors.defaultText,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            padding: 12,
          },
          list: {
            padding: 12,

            '& .MuiMenuItem-root.MuiButtonBase-root': {
              fontSize: 14,
              marginTop: 1,
              marginBottom: 1,
              transition: 'all .2s',
              color: functionalColors.defaultText,

              '& .MuiTouchRipple-root': {
                opacity: 0.2,
              },

              '&:hover, &:active, &.active, &.Mui-selected': {
                color: functionalColors.defaultText,
                background: alpha(_colors.primary.lighter, 0.4),
              },
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            background: 'transparent',
            transition: 'all .2s',

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: _colors.alpha.black[100],
              background: alpha(_colors.primary.lighter, 0.4),
            },
            '&.Mui-selected:hover': {
              background: alpha(_colors.primary.lighter, 0.4),
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.MuiButtonBase-root': {
              color: functionalColors.defaultText,

              '&:hover, &:active, &.active, &.Mui-selected': {
                color: functionalColors.defaultText,
                background: alpha(_colors.primary.lighter, 0.1),
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          iconOutlined: {
            color: _colors.alpha.black[50],
          },
          icon: {
            top: 'calc(50% - 14px)',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              opacity: 0.5,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:before, &:after': {
              borderBottom: 'none',
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '0.3rem',
            background: _colors.primary.lighter,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: alpha(_colors.primary.main, 0.5),
            },
            '& .MuiOutlinedInput-input': {
              padding: '0.75rem',
            },
            '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
              paddingRight: 6,
              background: _colors.primary.lighter,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: alpha(_colors.primary.main, 0.7),
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: alpha(_colors.primary.main, 0.8),
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            borderRadius: '0%',
            width: '40px',
            height: '40px',
            background: functionalColors.containerBackground,
            color: functionalColors.label,

            '.MuiSvgIcon-root': {
              width: '15.6px',
              height: '15.6px',
            },
            '&.Mui-checked': {
              color: colorScheme.white,
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        variants: [
          {
            props: { size: 'medium' },
            style: {
              height: '40px',
              padding: '0px 20px',
            },
          },
          {
            props: { variant: 'text' },
            style: {
              backgroundColor: functionalColors.textButtonBackground,
              color: _colors.primary.main,
              '.MuiButton-startIcon svg': {
                height: '20px',
                width: '20px',
              },
              ':hover': {
                backgroundColor: alpha(_colors.primary.lighter, 0.2),
              },
            },
          },
          {
            props: { variant: 'outlined', color: 'primary' },
            style: {
              border: `1px solid ${_colors.primary.main}`,
              color: _colors.primary.main,
            },
          },
          {
            props: { variant: 'outlined', disabled: true },
            style: {
              '&&': {
                color: alpha(functionalColors.disabledText, 0.4),
                background: functionalColors.disabledBackground,
              },
              '&& .MuiLoadingButton-loadingIndicator': {
                color: _colors.info.main,
              },
            },
          },
          {
            props: { variant: 'contained', disabled: true },
            style: {
              '&&': {
                color: alpha(functionalColors.disabledText, 0.5),
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            fontWeight: '500',
            textTransform: 'none',
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: '4px',
            minWidth: '140px',

            '.MuiSvgIcon-root': {
              transition: 'all .2s',
            },
          },
          startIcon: {
            '& svg': {
              height: '12px',
              width: '12px',
            },
          },
          endIcon: {
            '& svg': {
              height: '12px',
              width: '12px',
            },
          },
          containedSecondary: {
            backgroundColor: _colors.secondary.main,
            color: _colors.alpha.white[100],
            border: '1px solid ' + _colors.alpha.black[30],
          },
          outlinedSecondary: {
            backgroundColor: _colors.alpha.white[100],

            '&:hover, &.MuiSelected': {
              backgroundColor: _colors.alpha.black[5],
              color: _colors.alpha.black[100],
            },
          },
          sizeSmall: {
            padding: '6px 16px',
            lineHeight: 1.5,
          },
          sizeMedium: {
            padding: '8px 20px',
          },
          sizeLarge: {
            padding: '11px 24px',
          },
          textSizeSmall: {
            padding: '7px 12px',
          },
          textSizeMedium: {
            padding: '9px 16px',
          },
          textSizeLarge: {
            padding: '12px 16px',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            background: functionalColors.textButtonBackground,
            borderRadius: 0,
            padding: 8,
            '& .MuiTouchRipple-root': {
              borderRadius: 8,
            },
          },
          sizeSmall: {
            padding: 4,
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-containedPrimary.MuiLoadingButton-loading': {
              backgroundColor: alpha(_colors.primary.main, 0.5),
            },
            '&.MuiButton-containedPrimary .MuiLoadingButton-loadingIndicator': {
              color: lighten(colorScheme.secondary, 0.8),
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,

            '& > .MuiListItem-button': {
              transition: 'all .2s',

              '& > .MuiSvgIcon-root': {
                minWidth: 34,
              },

              '& .MuiTouchRipple-root': {
                opacity: 0.2,
              },
            },
            '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
              backgroundColor: alpha(_colors.primary.lighter, 0.4),
            },
            '& .MuiMenuItem-root.MuiButtonBase-root:active': {
              backgroundColor: alpha(_colors.primary.lighter, 0.4),
            },
            '& .MuiMenuItem-root.MuiButtonBase-root .MuiTouchRipple-root': {
              opacity: 0.2,
            },
          },
          padding: {
            padding: '12px',

            '& .MuiListItem-button': {
              borderRadius: 6,
              margin: '1px 0',
            },
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          colorPrimary: {
            fontWeight: 'bold',
            lineHeight: '40px',
            fontSize: 13,
            background: _colors.alpha.black[5],
            color: _colors.alpha.black[70],
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            boxShadow: 'none',
          },
        },
        variants: [
          {
            props: { color: 'highContrast' },
            style: {
              background: functionalColors.appBackground,
            },
          },
          {
            props: { color: 'default' },
            style: {
              background: functionalColors.cardBackground,
            },
          },
        ],
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: '20px',
          },
          action: {
            marginTop: -5,
            marginBottom: -5,
          },
          title: {
            fontSize: 15,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '20px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorSecondary: {
            background: _colors.alpha.black[5],
            color: _colors.alpha.black[100],

            '&:hover': {
              background: _colors.alpha.black[10],
            },
          },
          deleteIcon: {
            color: _colors.error.light,

            '&:hover': {
              color: _colors.error.main,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',

            '&.Mui-expanded': {
              margin: 0,
            },
            '&::before': {
              display: 'none',
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: false,
        },
        styleOverrides: {
          root: {
            borderRadius: 6,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 'bold',
          },
          colorDefault: {
            background: _colors.alpha.black[30],
            color: _colors.alpha.white[100],
          },
        },
      },
      MuiAvatarGroup: {
        styleOverrides: {
          root: {
            alignItems: 'center',
          },
          avatar: {
            background: _colors.alpha.black[10],
            fontSize: 13,
            color: _colors.alpha.black[70],
            fontWeight: 'bold',

            '&:first-of-type': {
              border: 0,
              background: 'transparent',
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            '&': {
              margin: '0px',
            },
          },
        },
        variants: [
          {
            props: { disabled: false },
            style: {
              '& .MuiInputBase-root': {
                background: alpha(colorScheme.primary, 0.15),
              },
            },
          },
          {
            props: { error: false },
            style: {
              '& .MuiFormHelperText-root': {
                color: functionalColors.label,
                fontWeight: 'normal',
                fontStyle: 'italic',
              },
            },
          },
          {
            props: { variant: 'filled' },
            style: {
              '& > .MuiFilledInput-root.Mui-disabled': {
                background: functionalColors.disabledBackground,
                '& .MuiInputBase-input': {
                  textFillColor: functionalColors.disabledText,
                  color: functionalColors.disabledText,
                },
              },
              '& > .MuiFilledInput-root:before': {
                borderBottom: `1px solid ${functionalColors.divider}`,
              },
              '& > .MuiFilledInput-root.Mui-disabled:before': {
                borderBottom: 'none',
              },
              '& > .MuiFilledInput-root': {
                borderRadius: '0px',
              },
              label: {
                display: 'none',
              },
              input: {
                color: functionalColors.defaultText,
                padding: '10px',
              },
            },
          },
          {
            props: { variant: 'standard' },
            style: {
              label: {
                color: functionalColors.label,
              },
              input: {
                color: functionalColors.defaultText,
              },
            },
          },
        ],
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: functionalColors.label,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: functionalColors.label,
          },
        },
      },
      MuiListItemAvatar: {
        styleOverrides: {
          alignItemsFlexStart: {
            marginTop: 0,
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          page: {
            fontSize: 13,
            fontWeight: 'bold',
            transition: 'all .2s',
          },
          textPrimary: {
            '&.Mui-selected': {
              boxShadow: _colors.shadows.primary,
            },
            '&.MuiButtonBase-root:hover': {
              background: _colors.alpha.black[5],
            },
            '&.Mui-selected.MuiButtonBase-root:hover': {
              background: _colors.primary.main,
            },
          },
        },
      },
      MuiToggleButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            color: _colors.primary.main,
            background: _colors.alpha.white[100],
            transition: 'all .2s',

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: _colors.alpha.white[100],
              background: _colors.primary.main,
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            margin: 0,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '& .MuiTouchRipple-root': {
              opacity: 0.3,
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            background: functionalColors.divider,
            border: 0,
            height: 1,
          },
          vertical: {
            height: 'auto',
            width: 1,

            '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
              height: 'auto',
            },
            '&.MuiDivider-absolute.MuiDivider-fullWidth': {
              height: '100%',
            },
          },
          withChildren: {
            '&:before, &:after': {
              border: 0,
            },
          },
          wrapper: {
            background: _colors.alpha.white[100],
            fontWeight: 'bold',
            height: 24,
            lineHeight: '24px',
            marginTop: -12,
            color: 'inherit',
            textTransform: 'uppercase',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: 0,
          },
          elevation0: {
            boxShadow: 'none',
          },
          elevation: {
            boxShadow: _colors.shadows.elevated,
          },
          elevation2: {
            boxShadow: _colors.shadows.mildElevation,
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            height: 6,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
              transform: 'none',
            },
            '& .MuiSlider-valueLabel': {
              borderRadius: 6,
              background: _colors.alpha.black[100],
              color: _colors.alpha.white[100],
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            height: 38,
            minHeight: 38,
            overflow: 'visible',
          },
          indicator: {
            height: 38,
            minHeight: 38,
            borderRadius: 6,
            backgroundColor: alpha(_colors.primary.dark, 0.5),
          },
          scrollableX: {
            overflow: 'visible !important',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: 0,
            height: 38,
            minHeight: 38,
            borderRadius: 6,
            transition: 'color .2s',
            textTransform: 'capitalize',

            '&.MuiButtonBase-root': {
              minWidth: 'auto',
              paddingLeft: 20,
              paddingRight: 20,
              marginRight: 4,
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              color: _colors.alpha.white[100],
              zIndex: 5,
              backgroundColor: _colors.primary.dark,
            },
            '&:hover': {
              color: _colors.primary.light,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          tag: {
            margin: 1,
          },
          root: {
            '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              right: 14,
            },
            '.MuiAutocomplete-inputRoot': {
              paddingTop: 4,
              paddingBottom: 4,
            },
          },
          clearIndicator: {
            background: _colors.error.lighter,
            color: _colors.error.main,
            marginRight: 8,

            '&:hover': {
              background: _colors.error.lighter,
              color: _colors.error.dark,
            },
          },
          popupIndicator: {
            color: _colors.alpha.black[50],

            '&:hover': {
              background: _colors.primary.lighter,
              color: _colors.primary.main,
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '0 !important',
            padding: '0 !important',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderCollapse: 'separate',
            borderSpacing: '0px 4px',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            background: 'none',
            '.MuiTableCell-head': {
              color: functionalColors.label,
            },
          },
          root: {
            transition: 'background-color .2s',
            backgroundColor: functionalColors.containerBackground,
            '&.MuiTableRow-hover:hover': {
              backgroundColor: alpha(_colors.primary.lighter, 0.1),
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottomColor: _colors.alpha.black[10],
            fontSize: 14,
            color: functionalColors.defaultText,
          },
          head: {
            textTransform: 'uppercase',
            fontSize: 13,
            fontWeight: 'bold',
            color: _colors.alpha.black[70],
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            '.MuiTablePagination-selectIcon': {
              color: 'inherit',
            },
          },
          toolbar: {
            color: functionalColors.defaultText,
            '& .MuiIconButton-root': {
              padding: 8,
            },
          },
          select: {
            '&:focus': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          message: {
            lineHeight: 1.5,
            fontSize: 14,
          },
          standardInfo: {
            color: _colors.info.main,
          },
          action: {
            color: _colors.alpha.black[70],
          },
        },
      },
      MuiTimelineDot: {
        styleOverrides: {
          root: {
            margin: 0,
            zIndex: 5,
            position: 'absolute',
            top: '50%',
            marginTop: -6,
            left: -6,
          },
          outlined: {
            backgroundColor: _colors.alpha.white[100],
            boxShadow: `0 0 0 6px ${_colors.alpha.white[100]}`,
          },
          outlinedPrimary: {
            backgroundColor: _colors.alpha.white[100],
            boxShadow: `0 0 0 6px ${_colors.alpha.white[100]}`,
          },
        },
      },
      MuiTimelineConnector: {
        styleOverrides: {
          root: {
            position: 'absolute',
            height: '100%',
            top: 0,
            borderRadius: 50,
            backgroundColor: _colors.alpha.black[10],
          },
        },
      },
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            minHeight: 0,
            padding: '8px 0',

            '&:before': {
              display: 'none',
            },
          },
          missingOppositeContent: {
            '&:before': {
              display: 'none',
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: alpha(_colors.alpha.black['100'], 0.95),
            padding: '8px 16px',
            fontSize: 13,
            boxShadow: _colors.shadows.mildElevation,
          },
          arrow: {
            color: alpha(_colors.alpha.black['100'], 0.95),
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 40,
            height: 22,
            padding: 0,
            display: 'flex',
            '& .MuiSwitch-switchBase': {
              padding: 2,
              '&.Mui-checked': {
                transform: 'translateX(18px)',
                color: themeColors.scheme.white,
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  backgroundColor: _colors.primary.main,
                },
                '&.Mui-disabled': {
                  color: themeColors.functional.disabledText,
                  opacity: 0.5,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  backgroundColor: themeColors.functional.disabledBackground,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
              width: 22,
              height: 22,
              borderRadius: '50%',
            },
            '& .MuiSwitch-track': {
              borderRadius: 15,
              opacity: 1,
              backgroundColor: alpha(themeColors.scheme.white, 0.35),
              boxSizing: 'border-box',
            },

            '& .MuiButtonBase-root': {
              position: 'absolute',
              padding: 0,
              transition:
                'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
            '& .MuiIconButton-root': {
              borderRadius: '50%',
            },
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            paddingTop: 20,
            paddingBottom: 20,
            background: _colors.alpha.black[5],
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.MuiStepIcon-completed': {
              color: _colors.success.main,
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'div',
            h4: 'div',
            h5: 'div',
            h6: 'div',
            subtitle1: 'div',
            subtitle2: 'div',
            body1: 'div',
            body2: 'div',
          },
        },
        styleOverrides: {
          gutterBottom: {
            marginBottom: 4,
          },
          paragraph: {
            fontSize: 17,
            lineHeight: 1.7,
          },
        },
      },
    },
    shape: {
      borderRadius: 10,
    },
    typography: {
      fontFamily:
        '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      h1: {
        fontWeight: 700,
        fontSize: 35,
      },
      h2: {
        fontWeight: 600,
        fontSize: 24,
      },
      h3: {
        fontWeight: 600,
        fontSize: 20,
        lineHeight: 1.4,
        color: functionalColors.defaultText,
      },
      h4: {
        fontWeight: 600,
        fontSize: 16,
      },
      h5: {
        fontWeight: 600,
        fontSize: 14,
      },
      h6: {
        fontSize: 15,
      },
      body1: {
        fontSize: 14,
      },
      body2: {
        fontSize: 14,
      },
      button: {
        fontWeight: 600,
      },
      caption: {
        fontSize: 13,
        textTransform: 'uppercase',
        color: _colors.alpha.black[50],
      },
      subtitle1: {
        fontSize: 14,
        color: _colors.alpha.black[70],
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: 15,
        color: _colors.alpha.black[70],
      },
      overline: {
        fontSize: 13,
        fontWeight: 700,
        textTransform: 'uppercase',
      },
    },
  };
}

const themeMap: { [key: string]: Theme } = {
  PureLightTheme,
  FlashlyDarkTheme,
};

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}
