import { Box, Button, Divider, Drawer, Stack, styled, useTheme } from '@mui/material';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import IconAltlayerLong from 'src/components/icons/IconAltlayer.tsx/IconAltlayerLong';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import HeaderButtons from '../Header/Buttons';
import IconCollapse from './SidebarMenu/IconCollapse';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        transition: width 0.2s;
        z-index: 7;
        left: 0;
        top: ${theme.header.height};
        height: 100%;
        position: fixed;
        padding-bottom: 68px;
        .collapse {
          right: -10px;
          top: 15px;
          z-index: 1;
          position: absolute;
          cursor: pointer;
          font-size: 1.25rem;
        }
`,
);

function Sidebar() {
  const { closeSidebar, isSidebarCollapsed, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        className="permanent-sidebar"
        sx={{
          width: isSidebarCollapsed ? theme.sidebar.collapsedWidth : theme.sidebar.width,
          visibility: {
            xs: 'hidden',
            lg: 'visible',
          },
        }}
      >
        <IconCollapse
          className="collapse"
          onClick={toggleSidebar}
          style={{
            transform: isSidebarCollapsed ? 'scaleX(-1)' : 'none',
          }}
        />
        <Scrollbar>
          <SidebarMenu collapsed={isSidebarCollapsed} />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.functionalColors.divider,
          }}
        />
        <Box p={2}>
          <Button
            color="warning"
            component={NavLink}
            fullWidth
            size="small"
            to="/login"
            variant="contained"
          >
            Sign Out
          </Button>
        </Box>
      </SidebarWrapper>
      <Drawer
        ModalProps={{ BackdropProps: { invisible: true } }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        className="mobile-menu-drawer"
        disableScrollLock
        elevation={9}
        onClose={closeSidebar}
        open={!isSidebarCollapsed}
        sx={{
          visibility: { lg: 'hidden' },
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        variant="temporary"
      >
        <SidebarWrapper
          sx={{
            top: 0,
            width: theme.sidebar.width,
          }}
        >
          <Scrollbar>
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent={'space-between'}
              my={2}
              px={2}
              rowGap={1}
              sx={{
                width: '100%',
              }}
            >
              <Stack justifyContent="center">
                <IconAltlayerLong sx={{ height: 20, width: 120 }} />
              </Stack>
              <HeaderButtons flexDirection="row" />
            </Stack>
            <Divider
              sx={{
                mx: theme.spacing(2),
                background: theme.functionalColors.divider,
              }}
            />
            <SidebarMenu collapsed={false} />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
