import TwitterIcon from '@mui/icons-material/Twitter';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchFlashLayerDetails } from 'src/api/flashlayer';
import { FlashLayerInfo } from 'src/api/models/flashlayer';
import ShareCardGif from 'src/assets/backgrounds/share_card.gif';
import ShareCardBg from 'src/assets/backgrounds/share_card_bg.png';
import ShareCardBgHeader from 'src/assets/backgrounds/share_card_bg_header.png';
import CopyableText from 'src/components/Copyable/CopyableText';
import { StatusLabel } from 'src/components/Label';
import AddNetworkButton from 'src/components/WalletConnection/AddNetworkButton';
import { PATHS, QUERY_KEYS } from 'src/constants';
import { useNetwork } from 'wagmi';

const ShareCard: FC<{ details: FlashLayerInfo; isLoading: boolean }> = ({ details, isLoading }) => {
  return (
    <Grid
      container
      direction="column"
      sx={{
        borderRadius: '1rem',
        height: '40rem',
        width: '30rem',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        className="share-card-bg-header"
        sx={{
          backgroundImage: `url(${ShareCardBgHeader})`,
          position: 'absolute',
          zIndex: 1,
          height: '10rem',
          width: '100%',
        }}
      />
      {isLoading ? (
        <Grid
          alignItems="center"
          container
          flex={8}
          justifyContent="center"
          pt={4}
          px={3}
          sx={{ backgroundImage: `url(${ShareCardBg})`, backgroundPositionY: -10 }}
        >
          <CircularProgress size={80} />
        </Grid>
      ) : (
        <Grid
          alignItems="flex-start"
          container
          flex={8}
          pt={4}
          px={3}
          sx={{ backgroundImage: `url(${ShareCardBg})`, backgroundPositionY: -10 }}
        >
          <Grid item xs={6}>
            <Typography>Chain name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography textAlign="end" variant="h4">
              ID: {details?.resources?.chainId}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h1">{details?.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Currency</Typography>
          </Grid>
          <Grid item textAlign="end" xs={6}>
            <Typography variant="h4">{details?.settings?.tokenSymbol}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Status</Typography>
          </Grid>
          <Grid item textAlign="end" xs={6}>
            <Typography variant="h5">{<StatusLabel deployment={details} />}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Explorer</Typography>
          </Grid>
          <Grid item textAlign="end" xs={10}>
            <CopyableText
              anchorGridProps={{ justifyContent: 'flex-end' }}
              isLink
              linkProps={{ sx: { color: 'white' } }}
              maxLength={40}
              text={details?.resources?.explorer}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Faucet</Typography>
          </Grid>
          <Grid item textAlign="end" xs={8}>
            <CopyableText
              anchorGridProps={{ justifyContent: 'flex-end' }}
              isLink
              linkProps={{ sx: { color: 'white' } }}
              maxLength={40}
              text={details?.resources?.faucet}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>RPC (https)</Typography>
          </Grid>
          <Grid item textAlign="end" xs={8}>
            <CopyableText
              anchorGridProps={{ justifyContent: 'flex-end' }}
              isLink
              linkProps={{ sx: { color: 'white' } }}
              maxLength={40}
              text={details?.resources?.rpc}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>RPC (wss)</Typography>
          </Grid>
          <Grid item textAlign="end" xs={8}>
            <CopyableText
              anchorGridProps={{ justifyContent: 'flex-end' }}
              isLink
              linkProps={{ sx: { color: 'white' } }}
              maxLength={40}
              text={details?.resources?.rpcWs}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Block Time</Typography>
          </Grid>
          <Grid item textAlign="end" xs={6}>
            <Typography variant="h5">{details?.settings?.blockTime} s</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Transaction Sequencing Mode</Typography>
          </Grid>
          <Grid item textAlign="end" xs={6}>
            <Typography variant="h5">
              {details?.settings?.fcfs ? 'First Come First Served' : 'Priority Gas Auction'}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Box
        className="share-card-bg-footer"
        flex={5}
        p={2}
        sx={{ backgroundImage: `url(${ShareCardGif})` }}
      />
    </Grid>
  );
};

const ShareMyFlashLayer = () => {
  const { chain } = useNetwork();

  const { id } = useParams();

  const { data, isLoading } = useQuery([QUERY_KEYS.GET_FL_DETAILS, id], fetchFlashLayerDetails, {
    enabled: Boolean(id),
  });

  const flashLayer = data?.flashlayer;
  const { explorer, faucet } = flashLayer?.resources || {};

  const shareTweetHref = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    `GM! Check out this Flash Layer deployed via @alt_layer's Rollup Launcher at ${window.location.origin}/share/${id}.\n\nSee this L2 in action at ${explorer}\n\nRequest for tokens at ${faucet}`,
  )}`;

  return (
    <>
      <Container sx={{ pt: 5 }}>
        <Grid alignItems="center" container justifyContent="center">
          <ShareCard details={flashLayer} isLoading={isLoading} />
        </Grid>
        <Grid container justifyContent="center">
          <Stack m={4} spacing={2} sx={{ width: '30rem' }}>
            <AddNetworkButton
              Component={Button}
              buttonText="Add to wallet"
              data={flashLayer}
              disabled={Number(flashLayer?.resources?.chainId) === chain?.id}
              fullWidth
              variant="contained"
            />
            <Button
              component={MuiLink}
              href={shareTweetHref}
              rel="noopener noreferrer"
              startIcon={<TwitterIcon sx={{ '&&': { width: 20, height: 20 } }} />}
              target="_blank"
              variant="contained"
            >
              Share
            </Button>
            <Button component={Link} fullWidth to={PATHS.HOME} variant="outlined">
              Go to Dashboard
            </Button>
          </Stack>
        </Grid>
      </Container>
    </>
  );
};

export default ShareMyFlashLayer;
