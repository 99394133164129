import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Link, Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useIsFetching } from '@tanstack/react-query';
import { BigNumber } from 'ethers';
import { FC, ReactNode, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ROLES } from 'src/api/models/auth';
import { FlashLayerInfo, FlashLayerStatus } from 'src/api/models/flashlayer';
import { PATHS, QUERY_KEYS } from 'src/constants';
import { parseUnitsSafe } from 'src/helpers/bigNumbers';
import { isDeploymentStuck } from 'src/helpers/flashlayer';
import { ConnectedTrans } from 'src/helpers/translation';
import { useBalance } from 'wagmi';

import IconActive from '../icons/IconActive';
import IconPending from '../icons/IconPending';
import IconTerminated from '../icons/IconTerminated';

export const getRoleLabel = (roleName: ROLES): JSX.Element => {
  switch (roleName) {
    default:
      return <Label color="warning">Undefined</Label>;
  }
};

const FlashingWarningLabel: FC<{ text: ReactNode; tooltipMsg?: ReactNode }> = ({
  text,
  tooltipMsg = <ConnectedTrans i18nKey="status.deploymentStuckMsg" />,
}) => {
  return (
    <Label color="warning">
      <Tooltip title={tooltipMsg}>
        <Stack direction="row">
          <WarningAmberIcon
            fontSize="small"
            sx={{
              mr: 0.5,
              color: theme => theme.palette.warning.main,
              '@keyframes glow': {
                from: {
                  opacity: 0,
                },

                to: {
                  opacity: 1,
                },
              },
              animation: '1s glow',
              animationIterationCount: 'infinite',
              animationDirection: 'alternate',
            }}
          />
          {text}
        </Stack>
      </Tooltip>
    </Label>
  );
};

const WithBalanceCheck: FC<{ deployment: FlashLayerInfo; minBalance?: BigNumber }> = ({
  children,
  deployment,
  minBalance = parseUnitsSafe('0.2', 'ether'),
}) => {
  const { l1ChainId } = deployment?.settings?.rollup || {};

  const accountToFund = deployment?.resources?.account;
  const { data: l1Balance } = useBalance({
    address: accountToFund,
    chainId: Number(l1ChainId),
  });
  const { data: beaconBalance } = useBalance({
    address: accountToFund,
    chainId: 9993, // alt beacon
  });
  const hasInsufficientFunds =
    l1Balance?.value?.lt(minBalance) && beaconBalance?.value?.lt(minBalance);

  if (l1ChainId && hasInsufficientFunds)
    return (
      <FlashingWarningLabel
        text="Additional Funding Advised"
        tooltipMsg={
          <Typography>
            One or more accounts are running out of funds. Please{' '}
            <Link component={RouterLink} to={`${PATHS.FUNDING_STATUS}?id=${deployment.id}`}>
              fund these accounts
            </Link>{' '}
            to ensure Rollup availability
          </Typography>
        }
      />
    );

  return <>{children}</>;
};

export const StatusLabel: FC<{ deployment: FlashLayerInfo }> = ({ deployment }) => {
  const deploymentStatus = deployment?.status;

  // This is required because the data fetched does not change and react will not know when to re-render the status label.
  const isBackgroundFetching = useIsFetching({ queryKey: [QUERY_KEYS.GET_LAYERS] });

  const isStuck = useMemo(() => {
    return isDeploymentStuck(deployment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBackgroundFetching]);

  switch (deploymentStatus) {
    case FlashLayerStatus.INITIALIZING: {
      return (
        <WithBalanceCheck deployment={deployment}>
          {isStuck ? (
            <FlashingWarningLabel text={<ConnectedTrans i18nKey="status.deploying" />} />
          ) : (
            <Label color="warning">
              <IconPending fontSize="small" sx={{ mr: 0.5 }} />
              <ConnectedTrans i18nKey="status.deploying" />
            </Label>
          )}
        </WithBalanceCheck>
      );
    }

    case FlashLayerStatus.RESUMING: {
      return (
        <WithBalanceCheck deployment={deployment}>
          {isStuck ? (
            <FlashingWarningLabel text={<ConnectedTrans i18nKey="status.resuming" />} />
          ) : (
            <Label color="warning">
              <IconPending fontSize="small" sx={{ mr: 0.5 }} />
              <ConnectedTrans i18nKey="status.resuming" />
            </Label>
          )}
        </WithBalanceCheck>
      );
    }

    case FlashLayerStatus.PENDING_FUNDS: {
      return (
        <Label color="warning">
          <IconPending fontSize="small" sx={{ mr: 0.5 }} />
          <ConnectedTrans i18nKey="status.pendingFunds" />
        </Label>
      );
    }

    case FlashLayerStatus.IN_QUEUE: {
      return (
        <WithBalanceCheck deployment={deployment}>
          {isStuck ? (
            <FlashingWarningLabel text={<ConnectedTrans i18nKey="status.queued" />} />
          ) : (
            <Label color="pending">
              <IconPending fontSize="small" sx={{ mr: 0.5 }} />
              <ConnectedTrans i18nKey="status.queued" />
            </Label>
          )}
        </WithBalanceCheck>
      );
    }

    case FlashLayerStatus.ACTIVE:
      return (
        <WithBalanceCheck deployment={deployment}>
          <Label color="success" sx={{ borderRadius: '2rem' }}>
            <IconActive fontSize="small" sx={{ mr: 0.5 }} />
            <ConnectedTrans i18nKey="status.active" />
          </Label>
        </WithBalanceCheck>
      );
    case FlashLayerStatus.TERMINATED:
      return (
        <Label color="error">
          <IconTerminated fontSize="small" sx={{ mr: 0.5 }} />
          <ConnectedTrans i18nKey="status.terminated" />
        </Label>
      );
    case FlashLayerStatus.TERMINATING:
      return (
        <Label color="error">
          <IconTerminated fontSize="small" sx={{ mr: 0.5 }} />
          <ConnectedTrans i18nKey="status.terminating" />
        </Label>
      );
    case FlashLayerStatus.FAILED:
      return (
        <Label color="error">
          <IconTerminated fontSize="small" sx={{ mr: 0.5 }} />
          <ConnectedTrans i18nKey="status.failed" />
        </Label>
      );
    case FlashLayerStatus.PUBLIC:
      return (
        <Label color="primary">
          <ConnectedTrans i18nKey="status.public" />
        </Label>
      );
    default:
      return <Label color="error">Undefined</Label>;
  }
};

interface LabelProps {
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'info' | 'pending';
}

const StyledTypography = styled(Typography)(
  ({ theme }) => `
      background-color: ${alpha(theme.functionalColors.containerBackground, 0.3)};
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      border-radius: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      font-weight: bold;
      white-space: nowrap;
      &.Label {
        &-primary {
          background-color: ${theme.palette.primary.lighter};
          color: ${theme.palette.primary.main}
        }

        &-secondary {
          background-color: ${theme.palette.secondary.lighter};
          color: ${theme.palette.secondary.main}
        }

        &-success {
          background-color: ${theme.palette.success.lighter};
          color: ${theme.palette.success.main}
        }

        &-pending {
          background-color: ${theme.palette.pending.lighter};
          color: ${theme.palette.pending.main}
        }

        &-warning {
          background-color: ${theme.palette.warning.lighter};
          color: ${theme.palette.warning.main}
        }

        &-error {
          background-color: ${theme.palette.error.lighter};
          color: ${theme.palette.error.main}
        }

        &-info {
          background-color: ${theme.palette.info.lighter};
          color: ${theme.palette.info.main}
        }
      }
`,
);

const Label: FC<LabelProps & TypographyProps> = ({ children, color = 'secondary', ...rest }) => {
  return (
    <StyledTypography className={'Label-root Label-' + color} {...rest}>
      {children}
    </StyledTypography>
  );
};

export default Label;
