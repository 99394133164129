import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Grid, GridProps, Tooltip, useTheme } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';

export interface CopyableProps {
  clipboardValue: string;
  showTooltip?: boolean;
  anchorGridProps?: GridProps;
}

const Copyable: FC<CopyableProps> = ({
  anchorGridProps,
  children,
  clipboardValue,
  showTooltip = true,
  ...rest
}) => {
  const theme = useTheme();
  const [isCopyable, setIsCopyable] = useState(false);
  const [recentlyCopied, setRecentlyCopied] = useState(false);

  const showCopyIcon = () => {
    setIsCopyable(true);
  };

  const hideCopyIcon = () => {
    setIsCopyable(false);
  };

  const handleCopy = (e: MouseEvent<SVGElement>) => {
    e?.stopPropagation();
    navigator.clipboard.writeText(clipboardValue);
    setRecentlyCopied(true);
    setTimeout(() => setRecentlyCopied(false), 1000);
  };

  return !clipboardValue ? (
    <>-</>
  ) : (
    <Tooltip
      PopperProps={{
        sx: {
          display: showTooltip || recentlyCopied ? 'block' : 'none',
        },
      }}
      arrow
      title={recentlyCopied ? 'Copied to Clipboard!' : clipboardValue}
      {...rest}
    >
      <Grid
        container
        flexWrap="nowrap"
        onMouseLeave={hideCopyIcon}
        onMouseOver={showCopyIcon}
        sx={{ position: 'relative' }}
        {...anchorGridProps}
      >
        {children}
        <Box sx={{ position: 'relative' }}>
          <ContentCopyIcon
            onClick={handleCopy}
            sx={{
              display: (isCopyable && clipboardValue?.length) > 0 ? 'block' : 'none',
              position: 'absolute',
              left: 0,
              padding: 0.3,
              cursor: 'pointer',
              '&:hover': {
                background: theme.palette.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </Grid>
    </Tooltip>
  );
};

export default Copyable;
