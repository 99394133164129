import { styled, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'src/constants';

import IconAltlayerLong from './IconAltlayerLong';
import IconAltlayerSmall from './IconAltlayerSmall';

const AltlayerLogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`,
);

interface IAltLayerLogo {
  sx?: SxProps;
}

const AltlayerLogo: FC<IAltLayerLogo> = ({ sx, ...rest }) => {
  const theme = useTheme();
  const widerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <AltlayerLogoWrapper
      sx={{ color: 'primary.light', width: { sm: 150, xs: 50 }, ...sx }}
      to={PATHS.HOME}
      {...rest}
    >
      {widerThanSm ? (
        <IconAltlayerLong sx={{ height: { sm: 20, md: 32 }, width: { sm: 120, md: 187 } }} />
      ) : (
        <IconAltlayerSmall />
      )}
    </AltlayerLogoWrapper>
  );
};

export default AltlayerLogo;
