import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { Box, Button, List, ListItem, ListSubheader, styled } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { ROLES } from 'src/api/models/auth';
import ComingSoonTooltip from 'src/components/ComingSoonTooltip';
import IconFlashGPT from 'src/components/icons/IconFlashGPT';
import { ENV, PATHS } from 'src/constants';
import { useAuthContext } from 'src/contexts/AuthContext';
import { hasAtLeastOneAllowedRole } from 'src/helpers/auth';

import IconAbiNinja from './IconAbiNinja';
import IconBilling from './IconBilling';
import IconBridge from './IconBridge';
import IconCreate from './IconCreate';
import IconDeployment from './IconDeployment';
import IconExplorer from './IconExplorer';
import IconFaucet from './IconFaucet';
import IconGuide from './IconGuide';
import IconManageFlashLayer from './IconManageFlashLayer';
import IconRemix from './IconRemix';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
    & > .collapse {
      position: absolute;
    }
    & > .MuiList-root {
      overflow: hidden;
      white-space: nowrap;
      '&:last-child': {
        borderBottom: none;
      }
    }
    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.heading};
      padding: ${theme.spacing(1, 3)};
      line-height: 1.4;
    }
`,
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
      .MuiListItem-root {
        padding: 0.5px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.palette.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          height: 44px;
          display: flex;
          color: ${theme.sidebar.textColor};
          background-color: transparent;
          border-radius: 0;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(0, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              height: 20px;
              width: 20px;
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.sidebar.heading};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }

          .MuiButton-endIcon {
            color: ${theme.sidebar.heading};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            padding: 0px 27px 0px 23px;
            border-left: 4px solid ${theme.palette.primary.main};
            transition: border-color 0s;
            background-color: ${
              theme.palette.mode === 'dark'
                ? theme.functionalColors.containerBackground
                : theme.palette.primary.main
            };
            color: ${
              theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.sidebar.textColor
            };

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${
                theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.sidebar.textColor
              };
              svg use {
                fill: ${
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary.main
                    : theme.sidebar.textColor
                };
              }
              svg polygon {
                fill: ${
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary.main
                    : theme.sidebar.textColor
                };
              }
              svg path {
                fill: ${
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary.main
                    : theme.sidebar.textColor
                };
              }
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
`,
);

interface IMenuChildren {
  icon: (props: any) => JSX.Element;
  name: string;
  to: PATHS | string;
  disabled?: boolean;
  allowedRoles?: ROLES[];
  loginRequired?: boolean;
}
interface IMenuItem {
  name: string;
  allowedRoles?: ROLES[];
  children: IMenuChildren[];
  loginRequired?: boolean;
}

function SidebarMenu({ collapsed }: { collapsed: boolean }) {
  const { isAuthenticated, user } = useAuthContext();
  const { t } = useTranslation();

  const menuContent: IMenuItem[] = useMemo(
    () => [
      {
        name: t('sidebar.flashLayerManagement'),
        loginRequired: true,
        children: [
          {
            icon: IconDeployment,
            name: t('sidebar.deployments'),
            to: PATHS.FLASHLAYER_DEPLOYMENTS,
          },
          {
            icon: IconCreate,
            name: t('buttons.create'),
            to: `${PATHS.FLASHLAYER_CREATION}?usecase=generic&method=custom`,
          },
          {
            icon: IconManageFlashLayer,
            name: t('sidebar.manage'),
            to: PATHS.FLASHLAYER_MANAGEMENT,
          },
        ],
      },
      {
        name: t('sidebar.tooling'),
        children: [
          {
            icon: IconRemix,
            name: t('sidebar.ide'),
            to: PATHS.IDE_COMPONENT,
            disabled: false,
          },
          {
            icon: IconFlashGPT,
            name: t('sidebar.intentDrivenDeployment'),
            to: PATHS.FLASH_GPT,
            disabled: false,
            loginRequired: true,
          },
          {
            icon: IntegrationInstructionsIcon,
            name: t('sidebar.contracts'),
            to: PATHS.CONTRACT_INTERACTION,
          },
          {
            icon: IconAbiNinja,
            name: t('sidebar.abiNinja'),
            to: 'http://abi-ninja.alt.technology/',
          },
        ],
      },
      {
        name: 'accounts',
        loginRequired: true,
        allowedRoles: [ROLES.ROOT, ROLES.ADMIN],
        children: [
          {
            icon: AdminPanelSettingsIcon,
            name: 'Admin Panel',
            to: PATHS.ADMIN_PANEL,
            allowedRoles: [ROLES.ROOT, ROLES.ADMIN],
          },
          {
            icon: IconBilling,
            name: 'Billing',
            to: PATHS.SUBSCRIPTION_MANAGEMENT,
            disabled: window.appConfig?.env === ENV.PROD,
          },
        ],
      },
      {
        name: t('sidebar.testnetLinks'),
        children: [
          {
            icon: IconBridge,
            name: t('networks.bridge'),
            to: 'https://testnet-rollup-bridge.altlayer.io',
          },
          {
            icon: IconExplorer,
            name: t('networks.explorer'),
            to: 'https://testnet-rollup-explorer.altlayer.io',
          },
          {
            icon: IconFaucet,
            name: t('sidebar.faucet'),
            to: 'https://testnet-faucet.altlayer.io',
          },
          {
            icon: IconGuide,
            name: t('sidebar.guide'),
            to: 'https://docs.altlayer.io/altlayer-documentation/testnet/overview',
          },
        ],
      },
    ],
    [t],
  );

  return (
    <>
      <MenuWrapper>
        {menuContent.map(list => {
          if (list.loginRequired && !isAuthenticated) {
            return null;
          }

          const hasAllowedListRoles = hasAtLeastOneAllowedRole(list.allowedRoles, user?.roles);

          return (
            hasAllowedListRoles && (
              <List
                component="div"
                key={list.name}
                subheader={
                  collapsed ? null : (
                    <ListSubheader component="div" disableSticky>
                      {list.name}
                    </ListSubheader>
                  )
                }
                sx={{
                  padding: '12px 0px',
                  borderBottom: collapsed ? '1px solid rgba(255, 255, 255, 0.2)' : 'none',
                  '&:first-of-type': {
                    paddingTop: collapsed ? '36px' : '11px',
                  },
                }}
              >
                <SubMenuWrapper>
                  {list.children.map(item => {
                    if (item.loginRequired && !isAuthenticated) {
                      return null;
                    }

                    const hasAllowedItemRoles = hasAtLeastOneAllowedRole(
                      item.allowedRoles,
                      user?.roles,
                    );

                    return (
                      <Fragment key={item.name}>
                        {hasAllowedItemRoles && (
                          <ComingSoonTooltip show={!!item.disabled}>
                            <ListItem component="div">
                              {item.to?.includes('http') ? (
                                <Button
                                  component={'a'}
                                  href={item.to}
                                  rel="noopener noreferrer"
                                  startIcon={<item.icon />}
                                  target="_blank"
                                >
                                  {!collapsed && item.name}
                                </Button>
                              ) : (
                                <Button
                                  component={RouterLink}
                                  disableRipple
                                  startIcon={<item.icon />}
                                  to={item.to}
                                >
                                  {!collapsed && item.name}
                                </Button>
                              )}
                            </ListItem>
                          </ComingSoonTooltip>
                        )}
                      </Fragment>
                    );
                  })}
                </SubMenuWrapper>
              </List>
            )
          );
        })}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
