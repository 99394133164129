import { ReactElement, ReactNode } from 'react';

export enum SUBSCRIPTION_TIER {
  UNSPECIFIED = 'SUBSCRIPTION_TIER_UNSPECIFIED',
  UI_FREE_TRIAL_2H = 'UI_FREE_TRIAL_2H', // For UI only
  UI_PROMO_STARTER_TIER = 'UI_PROMO_STARTER_TIER', // For UI only
  UI_PROMO_STARTER_PLUS_TIER = 'UI_PROMO_STARTER_PLUS_TIER', // For UI only
  UI_PROMO_DEV_TIER = 'UI_PROMO_DEV_TIER', // UI only
  UI_OASYS_DEV_TIER = 'UI_OASYS_DEV_TIER', // UI only
  UI_ARBITRUM_DEV_TIER = 'UI_ARBITRUM_DEV_TIER', // UI only
  RESTAKING_TRIAL = 'RESTAKING_TRIAL',
  PREMIUM_MONTHLY = 'SUBSCRIPTION_TIER_PREMIUM_MONTHLY',
  PREMIUM_YEARLY = 'SUBSCRIPTION_TIER_PREMIUM_YEARLY',
  DEV_MONTHLY = 'SUBSCRIPTION_TIER_DEV_MONTHLY',
  DEV_YEARLY = 'SUBSCRIPTION_TIER_DEV_YEARLY',
  STARTER_MONTHLY = 'SUBSCRIPTION_TIER_STARTER_MONTHLY',
  STARTER_YEARLY = 'SUBSCRIPTION_TIER_STARTER_YEARLY',
}

export interface SubscriptionInfo {
  tier: SUBSCRIPTION_TIER;
  quantity: number;
  remainQuantity: number;
  id: string;
  status: string;
  nextBillingTime: string;
}
export interface ListSubscriptionsResponse {
  subscriptions: SubscriptionInfo[];
}

export interface CreateSubscriptionResponse {
  subscriptionId: string;
  clientSecret: string;
  amount: string;
  currency: string;
}
export interface CreateSubscriptionRequest {
  tier: SUBSCRIPTION_TIER;
  quantity: number;
}

export interface UpdateSubscriptionRequest {
  id: string;
  quantity: number;
}

export interface CancelSubscriptionRequest {
  id: string;
}

export interface PriceInfo {
  tier: SUBSCRIPTION_TIER;
  amount: number;
  currency: string;
}
export interface ListPricingPlansResponse {
  prices: PriceInfo[];
}

export interface TierInfo {
  tier: SUBSCRIPTION_TIER;
  icon: ReactElement;
  label: ReactNode;
  description: ReactNode;
}

export interface PartnerTierInfo extends TierInfo {
  partnerName: string;
  chainIds: number[];
  flashLayerWhiteLabel?: string;
}
