import { useMediaQuery, useTheme } from '@mui/material';
import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';

type SidebarContext = {
  isSidebarCollapsed: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  setIsSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

export const SidebarProvider: FC = ({ children }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(!isLargeScreen);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const closeSidebar = () => {
    setIsSidebarCollapsed(true);
  };

  return (
    <SidebarContext.Provider
      value={{ isSidebarCollapsed, toggleSidebar, closeSidebar, setIsSidebarCollapsed }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
