import './forms/yupCustomMethods';

import { CssBaseline } from '@mui/material';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import ErrorBoundary from './components/ErrorBoundary';
import SuspenseLoader from './components/SuspenseLoader';

function App() {
  const content = useRoutes(router);

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <CssBaseline />
      <ErrorBoundary>{content}</ErrorBoundary>
    </Suspense>
  );
}

export default App;
