import { Box, Stack, StackProps, Typography } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import React, { FC, lazy, ReactElement, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import BaseLayout from 'src/layouts/BaseLayout';
import NavigationLayout from 'src/layouts/NavigationLayout';
// Do not use lazy loading for ShareMyFlashlayer in case it is interfering with meta tags
import ShareMyFlashLayer from 'src/pages/ShareMyFlashLayer';
import { useAccount, useNetwork } from 'wagmi';

import { ROLES } from './api/models/auth';
import { useAuthContext } from './contexts/AuthContext';
import { hasAtLeastOneAllowedRole } from './helpers/auth';
import Login from './pages/Login';
// Do not use lazy loading for Login, otherwise there will be glitchy behaviour on the login screen when app first loads.
import { PATHS } from './constants';

const Loader = (Component: React.FC) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const CreateFlashlayerThematic = Loader(lazy(() => import('src/pages/CreateFlashlayerThematic')));
const AdminPanel = Loader(lazy(() => import('src/pages/AdminPanel')));
const Deployments = Loader(lazy(() => import('src/pages/Deployments')));
const ManageFlashlayer = Loader(lazy(() => import('src/pages/ManageFlashlayer')));
const FundingStatus = Loader(lazy(() => import('src/pages/FundingStatus')));
const ConfigureBridge = Loader(lazy(() => import('src/forms/ConfigureBridge')));
const ManageSubscription = Loader(lazy(() => import('src/pages/ManageSubscription')));
const MakePayment = Loader(lazy(() => import('src/pages/ManageSubscription/MakePayment')));
const PaymentSuccess = Loader(lazy(() => import('src/pages/ManageSubscription/PaymentSuccess')));
const SelectPlan = Loader(lazy(() => import('src/pages/ManageSubscription/SelectPlan')));
const ContractStatus = Loader(lazy(() => import('src/pages/ContractStatus')));
const Rollup = Loader(lazy(() => import('src/pages/Rollup')));
const Contracts = Loader(lazy(() => import('src/pages/Contracts')));
const XERC20Page = Loader(lazy(() => import('src/forms/XERC20')));
const InitiateRollup = Loader(lazy(() => import('src/forms/InitiateRollup')));
const FinalizeRollup = Loader(lazy(() => import('src/forms/FinalizeRollup')));
const IDEComponent = Loader(lazy(() => import('src/pages/IDEComponent')));
const FlashGPT = Loader(lazy(() => import('src/pages/FlashGPT')));
const FlashGPTChat = Loader(lazy(() => import('src/pages/FlashGPT/FlashGPTChat')));
const FlashGPTDeploy = Loader(lazy(() => import('src/pages/FlashGPT/FlashGPTDeploy')));
const ManageAccessTokens = Loader(lazy(() => import('src/pages/ManageAccessTokens')));
const Disclaimer = Loader(lazy(() => import('src/pages/Legal/Disclaimer')));
const PrivacyPolicy = Loader(lazy(() => import('src/pages/Legal/PrivacyPolicy')));
// Status
const Status403 = Loader(lazy(() => import('src/pages/Status/Status403')));
const Status404 = Loader(lazy(() => import('src/pages/Status/Status404')));
const StatusMaintenance = Loader(lazy(() => import('src/pages/Status/Maintenance')));

const isCypressUser = Boolean(JSON.parse(window.localStorage.getItem('googleCypressToken')));

const ProtectedRoute: React.FC<{ children?: React.ReactElement; allowedRoles?: ROLES[] }> = ({
  allowedRoles,
  children,
}) => {
  const {
    isAuthenticated,
    isInitial,
    isLoading,
    setPostLoginPath,
    tryLoginWithRefreshToken,
    user,
  } = useAuthContext();

  const hasAllowedRoles = hasAtLeastOneAllowedRole(allowedRoles, user?.roles);

  if (!isCypressUser && (!hasAllowedRoles || !isAuthenticated)) {
    setPostLoginPath(window.location.href?.replace(window.location.origin, ''));
    tryLoginWithRefreshToken();
  }

  return isLoading || isInitial ? (
    <SuspenseLoader fullWidth />
  ) : isAuthenticated && hasAllowedRoles ? (
    <>{children || <Outlet />}</>
  ) : (
    <Navigate replace to="/login" />
  );
};

export const Web3Route: FC<
  { children: ReactElement; bypass?: boolean; chainId?: number } & StackProps
> = ({ bypass = false, chainId, children, ...rest }) => {
  const { chain } = useNetwork();
  const { t } = useTranslation();
  const { isConnected } = useAccount();

  const isRouteAccessible =
    bypass || (chainId ? chain?.id === chainId : !chain?.unsupported && isConnected);

  return isRouteAccessible ? (
    children
  ) : (
    <Stack alignItems="center" flexGrow={1} justifyContent="center" rowGap={2} {...rest}>
      <Typography variant="h2">Wallet not connected, or wrong network selected</Typography>
      <Typography variant="h4">
        To continue, please connect your wallet or switch to the correct network
      </Typography>
      <Box mt={1}>
        {chainId ? (
          <Typography>Please switch to chain ID: {chainId}</Typography>
        ) : (
          <ConnectButton label={t('buttons.connectWallet')} />
        )}
      </Box>
    </Stack>
  );
};

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate replace to={PATHS.LOGIN} />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate replace to="404" />,
          },
          {
            path: '403',
            element: <Status403 />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />,
          },
        ],
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'share',
    element: <NavigationLayout hideSidebar />,
    children: [{ path: ':id', element: <ShareMyFlashLayer /> }],
  },
  {
    path: 'flashlayers',
    element: (
      <ProtectedRoute>
        <NavigationLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate replace to="deployments" />,
      },
      {
        path: 'deployments',
        element: <Deployments />,
      },
      {
        path: 'create',
        element: <CreateFlashlayerThematic />,
      },
      {
        path: 'manage',
        element: <ManageFlashlayer />,
      },
      {
        path: 'fund',
        element: <FundingStatus />,
      },
      {
        path: 'bridge-config',
        element: <ConfigureBridge />,
      },
      {
        path: 'contracts',
        element: <ContractStatus />,
      },
      {
        path: 'initiate-rollup',
        element: (
          <Web3Route>
            <ProtectedRoute allowedRoles={[ROLES.ROOT, ROLES.ADMIN, ROLES.ROLLUP_USER]}>
              <InitiateRollup />
            </ProtectedRoute>
          </Web3Route>
        ),
      },
      {
        path: 'finalize-rollup',
        element: (
          <Web3Route>
            <ProtectedRoute allowedRoles={[ROLES.ROOT, ROLES.ADMIN, ROLES.ROLLUP_USER]}>
              <FinalizeRollup />
            </ProtectedRoute>
          </Web3Route>
        ),
      },
      {
        path: 'rollup',
        element: (
          <ProtectedRoute allowedRoles={[ROLES.ROOT, ROLES.ADMIN, ROLES.ROLLUP_USER]}>
            <Rollup />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: 'apps',
    element: <NavigationLayout />,
    children: [
      {
        path: '',
        element: <Navigate replace to="intent-driven-deployment" />,
      },
      {
        path: 'ide',
        element: <IDEComponent />,
      },
      {
        path: 'contracts',
        children: [
          { path: '', element: <Contracts /> },
          {
            path: 'xerc20',
            element: <XERC20Page />,
          },
        ],
      },
      {
        path: 'intent-driven-deployment',
        element: <ProtectedRoute />,
        children: [
          {
            path: '',
            element: <FlashGPT />,
          },
          {
            path: 'chat',
            element: <FlashGPTChat />,
          },
          {
            path: 'deploy',
            element: <FlashGPTDeploy />,
          },
        ],
      },
    ],
  },
  {
    path: 'admin',
    element: (
      <ProtectedRoute>
        <NavigationLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <AdminPanel />,
      },
    ],
  },
  {
    path: 'accounts',
    children: [
      {
        path: 'subscriptions',
        element: (
          <ProtectedRoute>
            <NavigationLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'manage',
            element: <ManageSubscription />,
          },
          {
            path: 'select',
            element: <SelectPlan />,
          },
          {
            path: 'payment',
            element: <MakePayment />,
          },
          {
            path: 'payment-success',
            element: <PaymentSuccess />,
          },
        ],
      },
    ],
  },
  {
    path: 'profile',
    element: (
      <ProtectedRoute>
        <NavigationLayout />
      </ProtectedRoute>
    ),
    children: [{ path: 'access-tokens', element: <ManageAccessTokens /> }],
  },
  {
    path: 'legal',
    element: <NavigationLayout />,
    children: [
      {
        path: 'disclaimer',
        element: <Disclaimer />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
    ],
  },
];

export default routes;
