import { FC } from 'react';
import { Trans, TransProps, useTranslation } from 'react-i18next';

export const ConnectedTrans: FC<TransProps<any>> = ({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <Trans t={t} {...props}>
      {children}
    </Trans>
  );
};
