import { Stack, StackProps } from '@mui/material';
import ComingSoonTooltip from 'src/components/ComingSoonTooltip';

import LanguageSwitcher from './LanguageSwitcher';
import HeaderNotifications from './Notifications';
import HeaderSearch from './Search';
import ThemeSwitcher from './ThemeSwitcher';

function HeaderButtons({ sx, ...props }: StackProps) {
  return (
    <Stack columnGap={1} direction="row" justifyContent="space-between" sx={sx} {...props}>
      <ComingSoonTooltip>
        <HeaderSearch />
      </ComingSoonTooltip>
      <ComingSoonTooltip>
        <HeaderNotifications />
      </ComingSoonTooltip>
      <ComingSoonTooltip>
        <ThemeSwitcher />
      </ComingSoonTooltip>
      <LanguageSwitcher iconOnly />
    </Stack>
  );
}

export default HeaderButtons;
