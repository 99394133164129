/* eslint-disable no-template-curly-in-string */
import { BigNumber, utils } from 'ethers';
import get from 'lodash/get';
import { ConnectedTrans } from 'src/helpers/translation';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: () => <ConnectedTrans i18nKey="errors.required" />,
    notType: ({ type }) => {
      return type === 'number'
        ? () => <ConnectedTrans i18nKey="errors.invalidNumber" />
        : () => <ConnectedTrans i18nKey="errors.invalidType" values={{ type }} />;
    },
  },
  string: {
    min: ({ min }) => <ConnectedTrans i18nKey="errors.minStringLength" values={{ min }} />,
    max: ({ max }) => <ConnectedTrans i18nKey="errors.maxStringLength" values={{ max }} />,
    email: () => <ConnectedTrans i18nKey="errors.invalidEmail" />,
    url: () => <ConnectedTrans i18nKey="errors.invalidUrl" />,
  },
  number: {
    min: ({ min }) => (
      <ConnectedTrans i18nKey="errors.minNumVal" values={{ min: utils.commify(min) }} />
    ),
    max: ({ max }) => (
      <ConnectedTrans i18nKey="errors.maxNumVal" values={{ max: utils.commify(max) }} />
    ),
    integer: () => <ConnectedTrans i18nKey="errors.invalidInt" />,
  },
});

yup.addMethod(
  yup.string,
  'bigNumber',
  function bigNumber(maxValue = '340282366920938463463374607431768211455', errorMsg) {
    return this.test(
      'is-big-number',
      errorMsg || <ConnectedTrans i18nKey="errors.invalidBigNum" />,
      function (value: string) {
        try {
          const bn = BigNumber.from(value);

          if (bn?.lte?.(BigNumber.from(maxValue)) && bn?.gte?.(BigNumber.from('1'))) {
            return true;
          }

          return this.createError();
        } catch (err) {
          return value === undefined || this.createError();
        }
      },
    );
  },
);

yup.addMethod(yup.string, 'address', function address() {
  return this.test(
    'is-address',
    () => <ConnectedTrans i18nKey="errors.invalidAddress" />,
    function (value: string) {
      try {
        utils.getAddress(value);

        return true;
      } catch (err) {
        return value === undefined || this.createError();
      }
    },
  );
});

yup.addMethod(
  yup.string,
  'tokenBalance',
  function tokenBalance(decimals = 18, allowZeroValue = false) {
    return this.matches(/(^\d+$|^\d+\.\d+$)/i, () => (
      <ConnectedTrans i18nKey="errors.invalidNumber" />
    ))
      .matches(RegExp(`(^\\d+$|^\\d+.\\d{1,${decimals}}$)$`, 'i'), () => (
        <ConnectedTrans i18nKey="errors.maxDecimals" />
      ))
      .matches(allowZeroValue ? /.*/i : /[1-9]+[0-9]*(\.[0-9]+)?/i, () => (
        <ConnectedTrans i18nKey="errors.nonZero" />
      ));
  },
);

export type Casing = 'uppercase' | 'lowercase' | 'any';
yup.addMethod(yup.string, 'alphanumeric', function alphanumeric(casing: Casing = 'any') {
  switch (casing) {
    case 'uppercase':
      return this.matches(/^[A-Z0-9]+$/, () => (
        <ConnectedTrans context={casing} i18nKey="errors.invalidAlphanumeric" />
      ));
    case 'lowercase':
      return this.matches(/^[a-z0-9]+$/, () => (
        <ConnectedTrans context={casing} i18nKey="errors.invalidAlphanumeric" />
      ));
    default:
      return this.matches(/^[a-zA-Z0-9]+$/, () => (
        <ConnectedTrans i18nKey="errors.invalidAlphanumeric" />
      ));
  }
});

yup.addMethod(yup.string, 'noUrl', function noUrl() {
  return this.matches(/^((?!www).)*$/gi, () => <ConnectedTrans i18nKey="errors.noUrl" />);
});

yup.addMethod(yup.string, 'noLeadingDigit', function noLeadingDigit() {
  return this.matches(/^[a-z]+[a-z0-9]*$/, () => <ConnectedTrans i18nKey="errors.noStartDigit" />);
});

yup.addMethod(
  yup.string,
  'isRegex',
  function isRegex(message = 'Please enter a valid javascript regular expression e.g. /abc/') {
    return this.test('is-regex', message, function (value) {
      const closingRegexIndex = value?.lastIndexOf('/');
      const expression = value?.substring(1, closingRegexIndex);
      const flags = value?.substring(closingRegexIndex + 1);

      try {
        RegExp(expression, flags);

        return true; // valid regex
      } catch (err) {
        return this.createError();
      }
    });
  },
);

yup.addMethod(
  yup.mixed,
  'uniqueInArray',
  function (filterCondition = (item: any, fieldValue: string) => item === fieldValue, pathToArray) {
    return this.test(
      'uniqueIn',
      () => <ConnectedTrans i18nKey="errors.duplicateValues" />,
      function (value, context) {
        if (pathToArray) {
          const allFormValues = (this as any).from[(this as any).from?.length - 1]?.value;
          const array = get(allFormValues, pathToArray);

          return array.filter((item: any) => filterCondition(item, value)).length < 2;
        }

        return context?.parent?.filter((item: any) => filterCondition(item, value)).length < 2;
      },
    );
  },
);

yup.addMethod(yup.array, 'unique', function (message, mapper = (a: any) => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

yup.addMethod(yup.number, 'step', function (stepValue, message) {
  return this.test(
    'step',
    message || <ConnectedTrans i18nKey="errors.invalidMultiple" values={{ stepValue }} />,
    function (value) {
      return Number(value) % stepValue === 0;
    },
  );
});
