import LanguageIcon from '@mui/icons-material/Language';
import {
  IconButton,
  IconButtonProps,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import i18n from 'src/i18n';

function LanguageSwitcher({ iconOnly, ...props }: IconButtonProps & { iconOnly?: boolean }) {
  const ref = useRef<any>(null);
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(state => !state);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const handleSelectLanguage = (languageCode: string) => () => {
    i18n.changeLanguage(languageCode);
    closeMenu();
  };

  return (
    <>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={closeMenu}
        open={showMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List component="nav" sx={{ p: 1 }}>
          <ListItemButton onClick={handleSelectLanguage('en')}>
            <ListItemText primary="English" />
          </ListItemButton>
          <ListItemButton onClick={handleSelectLanguage('ja')}>
            <ListItemText primary="日本語" />
          </ListItemButton>
        </List>
      </Popover>
      <Tooltip arrow title="Change Language">
        <IconButton color="primary" onClick={toggleMenu} ref={ref} {...props}>
          <LanguageIcon />
          {!iconOnly && <Typography>Change Language</Typography>}
        </IconButton>
      </Tooltip>
    </>
  );
}

export default LanguageSwitcher;
