import { MutationFunction, QueryFunction, QueryFunctionContext } from '@tanstack/react-query';

import { ROLES } from './models/auth';
import {
  AddTokenToBridgeRequest,
  CreateFlashLayerRequest,
  DestroyFlashLayerRequest,
  FlashLayerResult,
  FlashLayerStatus,
  ListFlashLayerResult,
  RestoreFlashLayerRequest,
  ResumeDeployFlashLayerRequest,
  TokenData,
  UpdateVerseProxyWhitelistReq,
  WithdrawTokensRequest,
  WithdrawTokensResult,
} from './models/flashlayer';
import { authUtil } from './auth';
import { instance } from './index';

const statusesRegardedAsActive = [
  FlashLayerStatus.ACTIVE,
  FlashLayerStatus.INITIALIZING,
  FlashLayerStatus.IN_QUEUE,
  FlashLayerStatus.PENDING_FUNDS,
  FlashLayerStatus.RESUMING,
];

export const fetchLayers: QueryFunction<ListFlashLayerResult> = async ({ queryKey }) => {
  const [, type] = queryKey || [];
  const statusesToQuery = type === 'active' ? statusesRegardedAsActive : undefined;
  const roles = authUtil.getRoles();
  const isAdmin = roles?.some((role: ROLES) => role === ROLES.ADMIN || role === ROLES.ROOT);
  // TODO: Use flashlayer/admin/list once we have admin-only logic separation
  const res = await instance.get(`${isAdmin ? '/flashlayer/list' : '/flashlayer/list'}`, {
    params: { status: statusesToQuery },
    paramsSerializer: {
      indexes: null, // send as ?status=A&status=B instead of ?status[]=A&status[]=B
    },
  });

  return res?.data;
};

export const searchFLs: MutationFunction<ListFlashLayerResult, { name: string }> = async ({
  name,
}) => {
  const roles = authUtil.getRoles();
  const isAdmin = roles?.some((role: ROLES) => role === ROLES.ADMIN || role === ROLES.ROOT);
  // TODO: Use flashlayer/admin/list once we have admin-only logic separation
  const res = await instance.get(
    `${isAdmin ? '/flashlayer/list' : '/flashlayer/list'}${`?name=${name}`}`,
  );

  return res?.data;
};

export const fetchPaginatedLayers: (
  context: QueryFunctionContext,
) => Promise<ListFlashLayerResult> = async ({ pageParam, queryKey }) => {
  const [, type, pageSize] = queryKey || [];
  const statusesToQuery = type === 'active' ? statusesRegardedAsActive : undefined;

  const roles = authUtil.getRoles();
  const isAdmin = roles?.some((role: ROLES) => role === ROLES.ADMIN || role === ROLES.ROOT);
  // TODO: Use flashlayer/admin/list once we have admin-only logic separation
  const res = await instance.get(`${isAdmin ? '/flashlayer/list' : '/flashlayer/list'}`, {
    params: { status: statusesToQuery, pageSize, pageToken: pageParam },
    paramsSerializer: {
      indexes: null, // send as ?status=A&status=B instead of ?status[]=A&status[]=B
    },
  });

  return res?.data;
};

export const fetchFlashLayerDetails: QueryFunction<
  FlashLayerResult,
  [key: string, flashLayerId: string]
> = async ({ queryKey }) => {
  const [, flashLayerId] = queryKey;
  const res = await instance.get(`/flashlayer/info/${flashLayerId}`);

  return res?.data;
};

export const createFlashLayer: MutationFunction<
  FlashLayerResult,
  CreateFlashLayerRequest
> = async reqBody => {
  const res = await instance.post('/flashlayer/create', reqBody);

  return res?.data;
};

export const createRestakingFL: MutationFunction<
  FlashLayerResult,
  CreateFlashLayerRequest
> = async reqBody => {
  const res = await instance.post('/flashlayer/restaking/trial/create', reqBody);

  return res?.data;
};

export const destroyFlashLayer: MutationFunction<
  unknown,
  DestroyFlashLayerRequest
> = async reqBody => {
  const res = await instance.post('/flashlayer/destroy', reqBody);

  return res?.data;
};

export const restoreFlashLayer: MutationFunction<
  unknown,
  RestoreFlashLayerRequest
> = async reqBody => {
  const res = await instance.post('/flashlayer/restore', reqBody);

  return res?.data;
};

export const resumeDevTierDeployment: MutationFunction<
  unknown,
  ResumeDeployFlashLayerRequest
> = async reqBody => {
  const res = await instance.post('/flashlayer/retry', reqBody);

  return res?.data;
};

export const addTokenToBridge: MutationFunction<
  unknown,
  AddTokenToBridgeRequest
> = async reqBody => {
  const res = await instance.post('/flashlayer/bridge/token/add', reqBody);

  return res?.data;
};

export const getTokenList: QueryFunction<
  TokenData[],
  [key: string, flashLayerId: string]
> = async ({ queryKey }) => {
  const [, flashLayerId] = queryKey;
  const res = await instance.get(`/flashlayer/bridge/${flashLayerId}`);

  const tokens: TokenData[] = res?.data?.tokens;

  console.log('res: ', res);

  return tokens;
};

export const updateVerseProxyWhitelists: MutationFunction<
  unknown,
  UpdateVerseProxyWhitelistReq
> = async reqBody => {
  const res = await instance.post('/flashlayer/access/update', reqBody);

  return res?.data;
};

export const withdrawTokens: MutationFunction<
  WithdrawTokensResult,
  WithdrawTokensRequest
> = async reqBody => {
  const res = await instance.post('/flashlayer/withdraw', reqBody);

  return res?.data;
};
