import IconFreeMint from 'src/components/icons/IconFreeMint';
import IconGasless from 'src/components/icons/IconGasless';
import IconPaidMint from 'src/components/icons/IconPaidMint';

import { ConnectedTrans } from './helpers/translation';

export enum ENV {
  PROD = 'production',
  STG = 'staging',
  DEV = 'development',
}

export const STORAGE_KEYS = {
  APP_THEME: 'app_theme',
  PREFERENCES: {
    DEPLOYMENT_VIEW: 'preferences.deployment_view',
  },
  ROLLUPS: {
    BATCH_SIZE: 'rollup.batch_size',
    SOURCE_CHAIN: 'rollup.source_chain',
    DEST_CHAIN: 'rollup.destination_chain',
    L1_TKN_ADDRESS: 'rollup.l1_token_address',
    L2_TKN_ADDRESS: 'rollup.l2_token_address',
    NONCE: 'rollup.nonce',
    TX_HASH: 'rollup.tx_hash',
  },
  CUSTOM_NETWORKS: 'custom_networks',
  EIGENLAYER_DEPOSITOR_ADDRESS: 'eigenlayer_depositor_address',
};

export enum QUERY_KEYS {
  GET_FL_DETAILS = 'GET_FL_DETAILS',
  GET_LAYERS = 'GET_LAYERS',
  GET_ORGANIZATIONS = 'GET_ORGANIZATIONS',
  GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS',
  GET_PRICING_PLANS = 'GET_PRICING_PLANS',
  GET_CONTRACTS = 'GET_CONTRACTS',
  GET_STATE_CHANGES = 'GET_STATE_CHANGES',
  GET_PROMO_CODES = 'GET_PROMO_CODES',
  GET_PROMO_CODE_INFO = 'GET_PROMO_CODE_INFO',
  GET_TOKENS_LIST = 'GET_TOKENS_LIST',
  GET_API_KEYS = 'GET_API_KEYS',
  GET_USER_PROFILE = 'GET_USER_PROFILE',
}

export const API_SUCCESS_CODE = 0;

export enum PATHS {
  HOME = '/',
  LOGIN = '/login',
  API_KEY_MANAGEMENT = '/profile/access-tokens',
  AUTH_SUCCESS = '/auth-success',
  ADMIN_PANEL = '/admin',
  FLASHLAYER_DEPLOYMENTS = '/flashlayers/deployments',
  FLASHLAYER_CREATION = '/flashlayers/create',
  FLASHLAYER_MANAGEMENT = '/flashlayers/manage',
  FLASHLAYER_CONTRACT_STATUS = '/flashlayers/contracts',
  MANAGE_ROLLUPS = '/flashlayers/rollup',
  INITIATE_ROLLUP = '/flashlayers/initiate-rollup',
  FINALIZE_ROLLUP = '/flashlayers/finalize-rollup',
  FUNDING_STATUS = '/flashlayers/fund',
  CONFIGURE_BRIDGE = '/flashlayers/bridge-config',
  CONTRACT_INTERACTION = '/apps/contracts',
  XERC20_PAGE = '/apps/contracts/xerc20',
  IDE_COMPONENT = '/apps/ide',
  FLASH_GPT = '/apps/intent-driven-deployment',
  FLASH_GPT_CHAT = '/apps/intent-driven-deployment/chat',
  FLASH_GPT_DEPLOY = '/apps/intent-driven-deployment/deploy',
  SUBSCRIPTION_MANAGEMENT = '/accounts/subscriptions/manage',
  SUBSCRIPTION_SELECT = '/accounts/subscriptions/select',
  SUBSCRIPTION_PAYMENT = '/accounts/subscriptions/payment',
  SUBSCRIPTION_PAYMENT_SUCCESS = '/accounts/subscriptions/payment-success',
  SUBSCRIPTION_MODIFICATION = '/accounts/subscriptions/modify',
  DISCLAIMER = '/legal/disclaimer',
  PRIVACY_POLICY = '/legal/privacy-policy',
}

export enum TX_PROCESSING_MODES {
  FCFS = 'fcfs',
  GASLESS = 'gasless',
  GAS_AUCTION = 'priority_gas_auction',
}

export const txProcessingOptions = [
  {
    icon: IconPaidMint,
    title: <ConnectedTrans i18nKey="forms.gasAuctionLabel" />,
    content: <ConnectedTrans i18nKey="forms.gasAuctionDesc" />,
    value: TX_PROCESSING_MODES.GAS_AUCTION,
  },
  {
    icon: IconGasless,
    title: <ConnectedTrans i18nKey="forms.gaslessLabel" />,
    content: <ConnectedTrans i18nKey="forms.gaslessDesc" />,
    value: TX_PROCESSING_MODES.GASLESS,
  },
  {
    icon: IconFreeMint,
    title: <ConnectedTrans i18nKey="forms.fairSequencingLabel" />,
    content: <ConnectedTrans i18nKey="forms.fairSequencingDesc" />,
    value: TX_PROCESSING_MODES.FCFS,
  },
];

export enum INTERFACE_IDS {
  ERC721 = '0x80ac58cd',
  ERC20 = '0x36372b07',
  XERC20 = '0x0fa598f6',
}
