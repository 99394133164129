import { BigNumber, BigNumberish, utils } from 'ethers';
import { isBytes } from 'ethers/lib/utils.js';

// logs error instead of throwing it

export const parseUnitsSafe: (value: string, unitName?: BigNumberish) => BigNumber = (
  value,
  unitName = 18,
) => {
  if (!value) return BigNumber.from(-1);

  try {
    return utils.parseUnits(value, unitName);
  } catch (err) {
    console.error(`Unable to cast ${value} into Ethers BigNumber`);

    return BigNumber.from(-1);
  }
};

export const formatUnitsSafe: (value: BigNumberish, unitName?: BigNumberish) => string = (
  value,
  unitName = 18,
) => {
  if (!value) return 'undefined';

  try {
    return utils.formatUnits(value, unitName);
  } catch (err) {
    console.error(`Unable to format ${isBytes(value) ? JSON.stringify(value) : value}`);

    return 'undefined';
  }
};

export const getTotalGenesisBalance = (genesisAccounts: { balance: string }[], decimals = 18) => {
  try {
    const sum = genesisAccounts
      ?.filter(({ balance }: { balance: string }) => !!balance)
      ?.reduce((acc: BigNumber, cur: { address: string; balance: string }) => {
        const balanceBn = parseUnitsSafe(cur.balance, decimals);

        return balanceBn.gt(0) ? acc.add(balanceBn) : acc;
      }, BigNumber.from(0));

    return utils.formatUnits(sum, decimals);
  } catch (err) {
    console.error('Error summing big numbers: ', err);
  }
};
