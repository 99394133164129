import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import KeyIcon from '@mui/icons-material/Key';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ComingSoonTooltip from 'src/components/ComingSoonTooltip';
import { PATHS } from 'src/constants';
import { useAuthContext } from 'src/contexts/AuthContext';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
      & > .MuiSvgIcon-root: {
        color: ${theme.functionalColors.defaultText}
      }
      padding-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(1)};
`,
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.functionalColors.containerBackground};
        padding: ${theme.spacing(2)};
`,
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        white-space: nowrap;
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.functionalColors.defaultText};
        display: block;
`,
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.functionalColors.defaultText}
`,
);

function HeaderUserbox() {
  const { logout, user } = useAuthContext();
  const theme = useTheme();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton
        color="secondary"
        fullWidth
        onClick={handleOpen}
        ref={ref}
        sx={{ px: { xs: 0, md: 3 }, minWidth: { xs: 40, sm: 70, md: 140 } }}
      >
        <Avatar
          alt={user?.name}
          src={user?.avatar}
          sx={{ width: '24px', height: '24px' }}
          variant="rounded"
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1,
              color: theme.functionalColors.defaultText,
            }}
          />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        open={isOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox display="flex" sx={{ minWidth: 210 }}>
          <Avatar alt={user?.name} src={user?.avatar} variant="rounded" />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">{user?.email}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List component="nav" sx={{ p: 1 }}>
          <Box>
            <ListItem button component={NavLink} to={PATHS.API_KEY_MANAGEMENT}>
              <KeyIcon fontSize="small" />
              <ListItemText primary="Access Tokens" sx={{ ml: 1 }} />
            </ListItem>
          </Box>
          <ComingSoonTooltip>
            <ListItem button component={NavLink} to="/management/profile/details">
              <AccountBoxTwoToneIcon fontSize="small" />
              <ListItemText primary="My Profile" sx={{ ml: 1 }} />
            </ListItem>
          </ComingSoonTooltip>

          <ComingSoonTooltip>
            <ListItem button component={NavLink} to="/dashboards/messenger">
              <InboxTwoToneIcon fontSize="small" />
              <ListItemText primary="Messenger" sx={{ ml: 1 }} />
            </ListItem>
          </ComingSoonTooltip>
          <ComingSoonTooltip>
            <ListItem button component={NavLink} to="/management/profile/settings">
              <AccountTreeTwoToneIcon fontSize="small" />
              <ListItemText primary="Account Settings" sx={{ ml: 1 }} />
            </ListItem>
          </ComingSoonTooltip>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={logout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            <Link component={NavLink} to="/login" variant="body1">
              Sign out
            </Link>
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
