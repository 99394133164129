import { createTheme, ThemeProvider } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { STORAGE_KEYS } from 'src/constants';
import i18n from 'src/i18n';

import { themeCreator } from './base';

export enum THEMES {
  PureLightTheme = 'PureLightTheme',
  FlashlyDarkTheme = 'FlashlyDarkTheme',
}

interface IThemeContext {
  setThemeName: Dispatch<SetStateAction<THEMES>>;
}
const ThemeContext = React.createContext({} as IThemeContext);

export const useThemeContext = () => useContext(ThemeContext);

const ThemeProviderWrapper: React.FC = props => {
  const curThemeName = localStorage.getItem(STORAGE_KEYS.APP_THEME) || THEMES.FlashlyDarkTheme;
  const [themeName, _setThemeName] = useState(curThemeName);
  const [theme, setTheme] = useState(themeCreator(themeName));

  const editTheme = useCallback((partialTheme: object) => {
    setTheme(createTheme(theme as ThemeOptions, partialTheme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', () => {
      switch (i18n.language) {
        case 'ja':
          editTheme({ sidebar: { width: '265px' } });
          break;
        default:
          editTheme({ sidebar: { width: '240px' } });
          break;
      }
    });
  }, [editTheme]);

  const setThemeName = useCallback((themeName: THEMES): void => {
    localStorage.setItem(STORAGE_KEYS.APP_THEME, themeName);
    _setThemeName(themeName);
  }, []);

  return (
    <ThemeContext.Provider value={{ setThemeName }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderWrapper;
